<template>
    <div class="receipt">
        <template v-if="selMsg != null">
            <template v-if="sort == 'paid'">
                <div class="receiptTitle">
                    <span>{{showMsg.school_name}}缴费电子收据</span>
                </div>
                <div class="receiptSubtitle">
                    <div class="receiptSubtitleLeft">
                        <span>业务订单号：{{selMsg.order_no}}</span>
                    </div>
                    <div class="receiptSubtitleRight">
                        <span>交易时间：{{selMsg.pay_time}}</span>
                    </div>
                </div>
                <div class="receiptMain receiptBT receiptBL clearfloat">
                    <div class="receiptMainLeft">
                        <div class="receiptMainLeftOne receiptBR receiptBB">
                            <div class="receiptMainLeftMsg">
                                <div class="receiptMainLeftMsgTop">
                                    <span>姓名</span>
                                </div>
                                <div class="receiptMainLeftMsgBottom">
                                    <span>{{selMsg.user_name}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="receiptMainLeftOne clearfloat">
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>学工号</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>{{selMsg.card_number}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>支付方式</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>{{selMsg.pay_type_name}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="receiptMainLeftOne clearfloat">
                            <div class="receiptMainLeftOneIn11  receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>缴费项目</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>{{selMsg.project_name}}</span>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>学年学期</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>无</span>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="receiptMainLeft">
                        <div class="receiptMainLeftOne2 receiptBR receiptBB">
                            <div class="receiptMainLeftMsg">
                                <div class="receiptMainLeftMsgTop" >
                                    <span>组织机构</span>
                                </div>
                                <div class="receiptMainLeftMsgBottom" >
                                    <span>{{selMsg.class_name}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="receiptMainLeftOne clearfloat">
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>应收总金额</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>￥{{selMsg.y_price}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>实收金额</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>￥{{selMsg.pay_price}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="receiptMainLeftOne3 receiptBL receiptBR receiptBB">
                    <span>人民币（大写）：{{selMsg.daxie}}</span>
                </div>
                <div class="receiptMainLeftOne3">
                    <span>收款单位（盖章）：{{showMsg.school_name}}</span>
                    <span style="float: right;">收款人：{{showMsg.school_name}}</span>
                </div>
                <div class="receiptMainLeftOne3">
                    <span>注：本电子收据仅作为线上缴费确认依据，不作为报销凭证</span>
                </div>
                <img class="receiptMainZhang" v-if="showMsg.receipt_seal" :src="showMsg.receipt_seal">
            </template>
            <template v-if="sort == 'refund'">
                <div class="receiptTitle">
                    <span>{{showMsg.school_name}}退款电子凭证</span>
                </div>
                <div class="receiptSubtitle">
                    <div class="receiptSubtitleLeft">
                        <span>退款订单号：{{selMsg.r_order_no}}</span>
                    </div>
                    <div class="receiptSubtitleRight">
                        <span>退款时间：{{selMsg.updated_at}}</span>
                    </div>
                </div>
                <div class="receiptMain receiptBT receiptBL clearfloat">
                    <div class="receiptMainLeft">
                        <div class="receiptMainLeftOne receiptBR receiptBB">
                            <div class="receiptMainLeftMsg">
                                <div class="receiptMainLeftMsgTop">
                                    <span>姓名</span>
                                </div>
                                <div class="receiptMainLeftMsgBottom">
                                    <span>{{selMsg.user_name}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="receiptMainLeftOne clearfloat">
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>学工号</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>{{selMsg.card_number}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>支付方式</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>{{selMsg.refund_type_name }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="receiptMainLeftOne clearfloat">
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>组织机构</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>{{selMsg.org_name}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>学年学期</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>无</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="receiptMainLeft">
                        <div class="receiptMainLeftOne2 receiptBR receiptBB">
                            <div class="receiptMainLeftMsg">
                                <div class="receiptMainLeftMsgTop" style="padding-top: 30px;">
                                    <span>缴费项目</span>
                                </div>
                                <div class="receiptMainLeftMsgBottom" style="padding-top: 10px;">
                                    <span>{{selMsg.project_name}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="receiptMainLeftOne clearfloat">
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>已缴总金额</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>￥{{selMsg.price}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>退款金额</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>￥{{selMsg.refund_price}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="receiptMainLeftOne3 receiptBL receiptBR receiptBB">
                    <span>人民币（大写）：{{selMsg.daxie}}</span>
                </div>
                <div class="receiptMainLeftOne3">
                    <span>收款单位（盖章）：{{showMsg.school_name}}</span>
                    <span style="float: right;">退款操作人：{{selMsg.op_name}}</span>
                </div>
                <div class="receiptMainLeftOne3">
                    <span>注：本电子收据仅作为线上缴费确认依据，不作为报销凭证</span>
                </div>
                <img class="receiptMainZhang" :src="showMsg.receipt_seal">
            </template>
            <template v-if="sort == 'signUpPaid'">
                <div class="receiptTitle">
                    <span>{{showMsg.school_name}}报名缴费电子收据</span>
                </div>
                <div class="receiptSubtitle">
                    <div class="receiptSubtitleLeft">
                        <span>订单号：{{selMsg.order_no}}</span>
                    </div>
                    <div class="receiptSubtitleRight">
                        <span>交易时间：{{selMsg.pay_time}}</span>
                    </div>
                </div>
                <div class="receiptMain receiptBT receiptBL clearfloat">
                    <div class="receiptMainLeft">
                        <div class="receiptMainLeftOne receiptBR receiptBB">
                            <div class="receiptMainLeftMsg">
                                <div class="receiptMainLeftMsgTop">
                                    <span>姓名</span>
                                </div>
                                <div class="receiptMainLeftMsgBottom">
                                    <span>{{selMsg.name}}</span>
                                    <span v-if="showIdCard">&nbsp;({{selMsg.newIdCard}})</span>
                                </div>
                            </div>
                        </div>
                        <div class="receiptMainLeftOne clearfloat">
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>学工号</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>暂无</span>
                                    </div>
                                </div>
                            </div>
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>支付方式</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>微信</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="receiptMainLeftOne clearfloat">
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>组织机构</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>{{showMsg.school_name}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>学年学期</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>无</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="receiptMainLeft">
                        <div class="receiptMainLeftOne2 receiptBR receiptBB">
                            <div class="receiptMainLeftMsg">
                                <div class="receiptMainLeftMsgTop" style="padding-top: 30px;">
                                    <span>缴费项目</span>
                                </div>
                                <div class="receiptMainLeftMsgBottom" style="padding-top: 10px;">
                                    <span>{{selMsg.project_name}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="receiptMainLeftOne clearfloat">
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>应收总金额</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>￥{{selMsg.pay_price}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>实收金额</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>￥{{selMsg.pay_price}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="receiptMainLeftOne3 receiptBL receiptBR receiptBB">
                    <span>人民币（大写）：{{selMsg.daxie}}</span>
                </div>
                <div class="receiptMainLeftOne3">
                    <span>收款单位（盖章）：{{showMsg.school_name}}</span>
                    <span style="float: right;">收款人：{{showMsg.school_name}}</span>
                </div>
                <div class="receiptMainLeftOne3">
                    <span>注：本电子收据仅作为线上缴费确认依据，不作为报销凭证</span>
                </div>
                <!--crossorigin="anonymous"-->
                <img v-if="showMsg.receipt_seal" class="receiptMainZhang" :src="showMsg.receipt_seal">
            </template>
            <template v-if="sort == 'signUpRefund'">
                <div class="receiptTitle">
                    <span>{{showMsg.school_name}}报名退款电子凭证</span>
                </div>
                <div class="receiptSubtitle">
                    <div class="receiptSubtitleLeft">
                        <span>退款订单号：{{selMsg.r_order_no}}</span>
                    </div>
                    <div class="receiptSubtitleRight">
                        <span>退款时间：{{selMsg.created_at}}</span>
                    </div>
                </div>
                <div class="receiptMain receiptBT receiptBL clearfloat">
                    <div class="receiptMainLeft">
                        <div class="receiptMainLeftOne receiptBR receiptBB">
                            <div class="receiptMainLeftMsg">
                                <div class="receiptMainLeftMsgTop">
                                    <span>姓名</span>
                                </div>
                                <div class="receiptMainLeftMsgBottom">
                                    <span>{{selMsg.user_name}}</span>
                                    <span v-if="showIdCard">&nbsp;({{selMsg.newIdCard}})</span>
                                </div>
                            </div>
                        </div>
                        <div class="receiptMainLeftOne clearfloat">
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>学工号</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>暂无</span>
                                    </div>
                                </div>
                            </div>
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>退款方式</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>{{selMsg.refund_type_name }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="receiptMainLeftOne clearfloat">
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>组织机构</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>{{showMsg.school_name}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>学年学期</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>无</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="receiptMainLeft">
                        <div class="receiptMainLeftOne2 receiptBR receiptBB">
                            <div class="receiptMainLeftMsg">
                                <div class="receiptMainLeftMsgTop" style="padding-top: 30px;">
                                    <span>缴费项目</span>
                                </div>
                                <div class="receiptMainLeftMsgBottom" style="padding-top: 10px;">
                                    <span>{{selMsg.project_name}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="receiptMainLeftOne clearfloat">
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>已缴总金额</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>￥{{selMsg.refund_price}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>退款金额</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>￥{{selMsg.refund_price}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="receiptMainLeftOne3 receiptBL receiptBR receiptBB">
                    <span>人民币（大写）：{{selMsg.daxie}}</span>
                </div>
                <div class="receiptMainLeftOne3">
                    <span>收款单位（盖章）：{{showMsg.school_name}}</span>
                    <span style="float: right;">退款操作人：{{selMsg.op_user_name}}</span>
                </div>
                <div class="receiptMainLeftOne3">
                    <span>注：本电子收据仅作为线上缴费确认依据，不作为报销凭证</span>
                </div>
                <img v-if="showMsg.receipt_seal" class="receiptMainZhang" :src="showMsg.receipt_seal">
            </template>
        </template>
    </div>
</template>

<script>
export default {
    name: "proofPaid",
    data() {
        return {
            showMsg: null,
            selMsg: null,
            sort:'',
            fileName:'',
        }
    },
    props: {
        proofData: Object
    },
    watch: {
        proofData() {
            this.dealWith();
        }
    },
    created() {
        this.dealWith();
    },
    methods: {
        dealWith() {
            this.showMsg = this.proofData.showMsg;
            this.selMsg = this.proofData.selMsg;
            this.sort = this.proofData.sort;
            this.fileName = this.proofData.fileName;
        }
    }
}
</script>

<style scoped>

@import "../assets/css/base.css";

.receiptBT {
    border-top: 1px solid #303133;
}

.receiptBL {
    border-left: 1px solid #303133;
}

.receiptBR {
    border-right: 1px solid #303133;
}

.receiptBB {
    border-Bottom: 1px solid #303133;
}

.receipt {
    width: 1440px;
    height: 630px;
    box-sizing: border-box;
    border: 1px solid #eee;
    position: relative;
    padding:0 40px ;
    background-color: #fff;
}

.receiptTitle {
    width: 100%;
    height: 100px;
    line-height: 100px;
    text-align: center;
    font-weight: bold;
    font-size: 40px;
    color: #303133;
}

.receiptSubtitle {
    width: 100%;
    height: 60px;
    line-height: 34px;
    font-size: 24px;
    color: #303133;
}

.receiptSubtitleLeft {
    height: 100%;
    float: left;
    color:#000000;
}

.receiptSubtitleRight {
    height: 100%;
    float: right;
    color: #303133;
}

.receiptMain {
    width: 100%;
    box-sizing: border-box;
}

.receiptMainLeft {
    width: 50%;
    box-sizing: border-box;
    float: left;
}

.receiptMainLeftOne {
    width: 100%;
    height: 90px;
    box-sizing: border-box;
}

.receiptMainLeftOneIn {
    width: 50%;
    height: 100%;
    box-sizing: border-box;
    float: left;
}
.receiptMainLeftOneIn11 {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    float: left;
}
.receiptMainLeftMsg {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.receiptMainLeftMsgTop {
    width: 100%;
    height: 50%;
    box-sizing: border-box;
    padding: 8px 10px 0 10px;
    color: #000;
    font-size: 24px;
    text-align: left;
}

.receiptMainLeftMsgBottom {
    width: 100%;
    height: 50%;
    box-sizing: border-box;
    padding: 0 10px;
    color: #000000;
    font-size: 24px;
    text-align: left;
    font-weight: bold;
}

.receiptMainLeftOne2 {
    width: 100%;
    height: 180px;
    box-sizing: border-box;
}

.receiptMainLeftOne3 {
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    line-height: 60px;
    text-align: left;
    color: #000;
    padding: 0 16px;
    font-size: 28px;
}

.receiptMainZhang {
    width: 250px;
    height: 250px;
    box-sizing: border-box;
    object-fit: contain;
    position: absolute;
    top: 354px;
    left: 68px;
}

</style>
